const topNav = document.getElementById('top-nav');


function toggleScrolled() {
    if (window.scrollY > 100) {
        topNav.classList.add('scrolled');
    } else {
        topNav.classList.remove('scrolled');
    }
}

function toggleFixedNav() {
    if (window.innerWidth >= 992) { // 992px is Bootstrap's lg breakpoint
        topNav.classList.add('fixed-top');
    } else {
        topNav.classList.remove('fixed-top');
    }
}

toggleFixedNav();

window.addEventListener('scroll', toggleScrolled);
window.addEventListener('resize', toggleFixedNav);